<template>
  <div ref="el" class="bg-white md:rounded-minilu-xl">
    <div
      class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl"
      :class="{
        '!pt-0': dialogStore.headline && isInDialog,
      }"
    >
      <Form
        id="reset-password-form"
        headline="changePassword.headline"
        submit-label="changePassword.submit"
        :is-loading="isLoading"
        @submit="onSubmit"
      >
        <template #errors>
          <div
            v-if="errors.length && errors.includes(ErrorIdent.UNKNOWN_ERROR)"
          >
            <UnknownError />
          </div>
          <div
            v-else-if="
              errors.length && errors.includes(ErrorIdent.INVALID_CREDENTIALS)
            "
          >
            <ErrorFrame>
              <div class="flex flex-col gap-sm">
                <div>
                  {{ t('changePassword.error.wrongPW') }}
                </div>
              </div>
            </ErrorFrame>
          </div>
        </template>
        <template #default>
          <Password
            id="oldPassword"
            name="oldPassword"
            label="changePassword.labels.oldPassword"
            class="mb-md"
            autofocus
            @click-forgot="() => emit('click-forgot')"
          />
          <PasswordValidation
            label="changePassword.labels.newPassword"
            confirm-label="changePassword.labels.confirmPassword"
            :autofocus="false"
          />
        </template>
      </Form>
    </div>
  </div>
</template>
<script setup lang="ts">
import PasswordValidation from '~/components/formFields/passwordValidation/minilu/passwordValidation.vue';
import Form from '~/components/formFields/form/form.vue';
import { useChangePasswordForm } from '../useChangePasswordForm';
import UnknownError from '~/components/formFields/errors/unknown/unknownError.vue';
import { ErrorIdent } from '~/@types/errorIdents';
import { useDialogStore } from '@/stores/useDialogStore';
import Password from '~/components/formFields/password/minilu/password.vue';
import ErrorFrame from '~~/src/components/formFields/components/errorFrame/errorFrame.vue';

const emit = defineEmits<{
  (e: 'success'): void;
  (e: 'click-forgot'): void;
}>();

const { t } = useTrans();

const { showSuccess, isLoading, onSubmit, errors } = useChangePasswordForm();
const dialogStore = useDialogStore();
const el = ref<HTMLElement | null>(null);

const isInDialog = useIsInDialog(el);

watch(showSuccess, (val) => {
  if (val) emit('success');
});
</script>
